import * as Sentry from '@sentry/browser';

export default function SentryInitialization() {
    if (!import.meta.env.VITE_SENTRY_DSN) {
        return;
    }
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        environment: import.meta.env.VITE_APP_ENV,
        release: import.meta.env.VITE_APP_VERSION,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.thirdPartyErrorFilterIntegration({
                filterKeys: [import.meta.env.VITE_SENTRY_APPLICATION_KEY],

                // Defines how to handle errors that contain third party stack frames.
                // Possible values are:
                // - 'drop-error-if-contains-third-party-frames'
                // - 'drop-error-if-exclusively-contains-third-party-frames'
                // - 'apply-tag-if-contains-third-party-frames'
                // - 'apply-tag-if-exclusively-contains-third-party-frames'
                behaviour: 'apply-tag-if-contains-third-party-frames',
            }),
        ],
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        tracePropagationTargets: [
            '127.0.0.1',
            'localhost',
            'acc-mybonds.fastnedcharging.com',
            'mybonds.fastnedcharging.com',
            'test-mybonds.fastnedcharging.com',
        ],
    });
}
