const LISTENERS = {};

export const DOM_READY = Symbol('DOM_READY');

export const PAYMENT_METHOD_WAS_CHANGED = Symbol('PAYMENT_METHOD_CHANGED');

export const PAYMENT_METHOD_WAS_LOADED = Symbol('PAYMENT_METHOD_LOADED');

export const ACCOUNT_TYPE_WAS_CHANGED = Symbol('ACCOUNT_TYPE_CHANGED');

export const ACCOUNT_TYPE_WAS_LOADED = Symbol('ACCOUNT_TYPE_LOADED');

export const dispatch = (eventName, payload) => {
    if (typeof LISTENERS[eventName] === 'undefined') {
        return;
    }
    LISTENERS[eventName].forEach((fn) => {
        fn(payload);
    });
};

export const observe = (eventName, listener) => {
    if (typeof LISTENERS[eventName] === 'undefined') {
        LISTENERS[eventName] = [];
    }
    LISTENERS[eventName].push(listener);
};
