import {
    dispatch,
    DOM_READY,
    observe,
    PAYMENT_METHOD_WAS_CHANGED,
    PAYMENT_METHOD_WAS_LOADED,
} from './observer';

export const paymentMethodInputEnhancer = function (el) {
    el.addEventListener('change', function (e) {
        dispatch(PAYMENT_METHOD_WAS_CHANGED, e.target.value);
    });

    observe(DOM_READY, () => {
        dispatch(PAYMENT_METHOD_WAS_LOADED, el.value);
    });
};
