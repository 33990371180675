import { slideDown, slideUp } from 'slide-anim';
import {
    ACCOUNT_TYPE_WAS_CHANGED,
    ACCOUNT_TYPE_WAS_LOADED,
    observe,
} from './observer';

const toggle = (accountType, el) => {
    if (accountType === 'business') {
        slideDown(el);
    } else {
        slideUp(el);
    }
};

export const cocNumberEnhancer = function (el) {
    observe(ACCOUNT_TYPE_WAS_CHANGED, (accountType) => toggle(accountType, el));

    observe(ACCOUNT_TYPE_WAS_LOADED, (accountType) => toggle(accountType, el));
};
