import {
    ACCOUNT_TYPE_WAS_CHANGED,
    ACCOUNT_TYPE_WAS_LOADED,
    dispatch,
    DOM_READY,
    observe,
} from './observer';

export const accountTypeInputEnhancer = function (el) {
    el.addEventListener('change', function (e) {
        dispatch(ACCOUNT_TYPE_WAS_CHANGED, e.target.value);
    });

    observe(DOM_READY, () => {
        if (el.checked) {
            dispatch(ACCOUNT_TYPE_WAS_LOADED, el.value);
        }
    });
};
