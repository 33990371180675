import { slideDown, slideUp } from 'slide-anim';
import {
    observe,
    PAYMENT_METHOD_WAS_CHANGED,
    PAYMENT_METHOD_WAS_LOADED,
} from './observer';

const handle = (paymentMethod, el) => {
    if (paymentMethod === 'banktransfer') {
        slideDown(el);
    } else {
        slideUp(el);
    }
};

export const showBankTransferWarning = (el) => {
    observe(PAYMENT_METHOD_WAS_CHANGED, (paymentMethod) =>
        handle(paymentMethod, el)
    );

    observe(PAYMENT_METHOD_WAS_LOADED, (paymentMethod) =>
        handle(paymentMethod, el)
    );
};
